<template>
  <div>
    
    <van-cell-group title="预约信息">
      <van-field 
        v-model="orderForm.jg_name" 
        label="预约机构" 
        required 
        placeholder="请选择预约机构" 
        @click="openHospital()" 
        :rules="[{ required: true, message: '请选择预约机构' }]" 
        readonly 
        is-link
      />
      <van-action-sheet 
        v-model="hospitals.show" 
        :actions="hospitals.data" 
        @select="onSelectHospital" 
      />
      <van-field 
        label="预约日期" 
        v-model="orderForm.date" 
        @click="order_date.show = true" 
        placeholder="请选择预约日期" 
        required 
        :rules="[{ required: true, message: '请选择预约日期！' }]"
        readonly 
        is-link
      />
      <van-calendar 
        v-model="order_date.show" 
        @confirm="selectDate"
        color="#07c160"
        :show-confirm="false"
        :min-date="min_date"
        :max-date="max_date"
      />
      <van-field 
        v-model="orderForm.doctor_name" 
        label="预约医生" 
        required 
        placeholder="请选择预约医生" 
        @click="openDoctor" 
        :rules="[{ required: true, message: '请选择预约医生' }]" 
        readonly 
        is-link
      />
      <van-action-sheet v-model="doctors.show" :actions="doctors.data" @select="selectDoctor" />
      <van-notice-bar
        wrapable
        :scrollable="false"
        color="#07c160"
        background="#ecf9ff"
        :text="'擅长：'+orderForm.doctor_skill"
        v-if="orderForm.doctor_skill"
        style="text-align:left"
      />
      <div class="order_time_title" v-if="order_time.am">
        <van-divider
          :style="{ color: '#07c160', borderColor: '#07c160', padding: '0 16px' }"
        >
          上午
        </van-divider>
      </div>
      <div class="order_time">
        <van-button
          v-for="(item, index) in order_time.am"
          :key="index"
          @click="onSelectTime(item)"
          :disabled="item.is_can_order == 0"
          :plain="orderForm.time != item.time"
          type="primary"
          size="small"
          native-type="button"
        >
          {{ item.time }}
        </van-button>
      </div>
      <div class="order_time_title" v-if="order_time.pm">
        <van-divider
          :style="{ color: '#07c160', borderColor: '#07c160', padding: '0 16px' }"
        >
          下午
        </van-divider>
      </div>
      <div class="order_time">
        <van-button
          v-for="(item, index) in order_time.pm"
          :key="index"
          @click="onSelectTime(item)"
          :disabled="item.is_can_order == 0"
          :plain="orderForm.time != item.time"
          size="small"
          type="primary"
          native-type="button"
        >
          {{ item.time }}
        </van-button>
      </div>
      <van-field 
        v-model="orderForm.period_time_name" 
        label="预约时长" 
        required 
        placeholder="请选择预约时长" 
        @click="period_time.show = true" 
        :rules="[{ required: true, message: '请选择预约时长' }]" 
        readonly 
        is-link
      />
      <van-action-sheet v-model="period_time.show" :actions="period_time.data" @select="selectPeriodTime" />

      <van-field 
        v-model="orderForm.yyks_name" 
        label="预约科室" 
        required 
        placeholder="请选择预约科室" 
        @click="offices.show = true" 
        :rules="[{ required: true, message: '请选择预约科室' }]" 
        readonly 
        is-link
      />
      <van-action-sheet v-model="offices.show" :actions="offices.data" @select="selectOffice" />

      <van-field 
        v-model="orderForm.yysx_name" 
        label="预约事项" 
        required 
        placeholder="请选择预约事项" 
        @click="openItem" 
        :rules="[{ required: true, message: '请选择预约事项' }]" 
        readonly 
        is-link
      />
      <van-action-sheet v-model="items.show" :actions="items.data" @select="selectItem" />
      
    </van-cell-group>
  </div>
</template>

<script>
// @ is an alias to /src
import dayjs from "dayjs";

export default {
  name: "Home",
  components: { },
  props:{
    orderForm:Object
  },
  data() {
    return {
      loading: false,
      min_date: new Date(2022, 5, 13),
      max_date: new Date(2022, 5, 20),
      hospitals: {
        show:false,
        data:[]
      },
      offices: {
        show: false,
        data: []
      },
      doctors: {
        show: false,
        data: []
      },
      period_time:{
        show:false,
        data:[
          {'id':15,'name':'15分钟'},
          {'id':30,'name':'30分钟'},
          {'id':45,'name':'45分钟'},
          {'id':60,'name':'1小时'},
          {'id':90,'name':'1.5小时'},
          {'id':120,'name':'2小时'},
        ]
      },
      items: {
        show:false,
        data:[]
      },
      user: {},
      order_date: {
        show: false
      },
      order_time: {},
      // yuyueForm: {
      //   jgbm: "",
      //   jg_name:"",
      //   yyksbm: "",
      //   yyks_name:"",
      //   yysxxh: "",
      //   yysx_name:"",
      //   rybm: "", //医生人员编码
      //   doctor_userid:"",
      //   doctor_name: "",
      //   doctor_skill: "",
      //   date: "",
      //   time: "",
      //   period_time:30,
      //   period_time_name:"30分钟",
      //   items: "",
      // },
    };
  },
  created() {
    this.getDate();
    
    this.getOffices();
    //   this.get_date();
    // console.log(dayjs())
    document.title = "企业微信代预约";
  },
  methods: {
    getHospital() {
      this.$emit('change','jgbm','')
      this.$emit('change','jg_name','')
      this.$http.get("hospital_city",{params:this.orderForm}).then((res) => {
        this.hospitals.data = res.data.data;
      });
    },
    openHospital(){
      if(!this.orderForm.city_code){
          this.$dialog.alert({
          message: '请先选择城市！',
          theme: 'round-button',
        })
        return false;
      }
      this.hospitals.show = true
    },
    onSelectHospital(item){
      this.$emit('change','jgbm',item.jgbm)
      this.$emit('change','jg_name',item.name)
      this.hospitals.show = false
      this.getDoctors()

    },
    getDate() {
      this.min_date = new Date(dayjs());
      this.max_date = new Date(dayjs().add(30, "day"));
    },


    selectDate(date) {
      this.$emit('change','date',dayjs(date).format('YYYY-MM-DD'))
      this.order_date.show = false;
      this.getDoctors();
    },
    openDoctor(){
      if(!this.orderForm.city_code){
          this.$dialog.alert({
          message: '请先选择城市！',
          theme: 'round-button',
        })
        return false;
      }
      if(!this.orderForm.jgbm){
          this.$dialog.alert({
          message: '请先选择机构！',
          theme: 'round-button',
        })
        return false;
      }
      if(!this.orderForm.date){
          this.$dialog.alert({
          message: '请先选择预约日期！',
          theme: 'round-button',
        })
        return false;
      }

      this.doctors.show = true
    },

    getDoctors() {
      this.$emit('change','doctor_name','')
      this.$emit('change','rybm','')
      this.$emit('change','doctor_skill','')
      if(this.orderForm.jgbm == '' || this.orderForm.date == ''){
        return false;
      }
      this.$http.post("pb_doctor", this.orderForm).then(res => {
        this.doctors.data = res.data.data;
      });
      this.getOrderTime();
    },
    selectDoctor(item) {
      this.$emit('change','doctor_name',item.name)
      this.$emit('change','rybm',item.rybm)
      this.$emit('change','doctor_userid',item.ygh)
      this.$emit('change','doctor_skill',item.skills)
      this.doctors.show = false;
      this.getOrderTime();
    },
    getOrderTime() {
      if(!this.orderForm.rybm){
        return false;
      }
      this.$emit('change','time','')
      this.order_time = {};
      this.$http.post("order_time", this.orderForm).then(res => {
        this.order_time = res.data.data;
      });
    },
    onSelectTime(item) {
      this.$emit('change','time',item.time)
    },
    selectPeriodTime(item){
      this.$emit('change','period_time_name',item.name)
      this.$emit('change','period_time',item.id)
      this.period_time.show = false
    },
    getOffices() {
      this.$http.get("office").then(res => {
        this.offices.data = res.data.data;
      });
    },
    selectOffice(item){
      this.$emit('change','yyks_name',item.name)
      this.$emit('change','yyksbm',item.yyksbm)
      this.offices.show = false
      this.getItems()
    },
    getItems() {
      this.$emit('change','yysxxh','')
      this.$emit('change','yysx_name','')
      this.$http.post("order_item", this.orderForm).then(res => {
        this.items.data = res.data.data;
      });
    },
    openItem(){
      if(!this.orderForm.yyksbm){
          this.$dialog.alert({
          message: '请先选择科室！',
          theme: 'round-button',
        })
        return false;
      }
      this.items.show = true
    },

    selectItem(item){
      this.$emit('change','yysxxh',item.xh)
      this.$emit('change','yysx_name',item.name)
      this.items.show = false
    }
  },
  watch: {
    "orderForm.city_code": function(val) {
      this.getHospital();
    },

   
  },
};
</script>
<style scoped>
.van-cell-group__title {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 16px;
  color: #07c160;
}
.van-cell {
  padding: 16px !important;
}
.van-cell__title {
  text-align: left;
}
.van-cell__value {
  text-align: left !important;
  min-width:56%;
}
.appointment {
  background: url(/static/images/yuyue_bg.png) no-repeat fixed top;
  margin-bottom: 80px;
}
.appointment form {
  padding: 10px;
}
.time_select {
  background-color: #3f51b5 !important;
  color: white !important;
}
.notice {
  text-align: left;
}
.order_time_title{
  margin:10px 0px;
  text-align:left;
}
.order_time{
  display:inline-block;

}
.order_time button{
  margin:5px;
  float:left;
}
</style>
