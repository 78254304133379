<template>
  <div class="appointment">
    <mu-container>
      <mu-row>
        <mu-col span="12">
          <mu-card style="width: 100%; margin: 0px auto; padding-bottom: 20px">
            <mu-card-media>
              <img src="/static/images/yuyue_top.jpg" />
            </mu-card-media>
            <mu-card-title
              title="可恩口腔企业微信代预约通道"
              sub-title="预约信息会直接存入his系统"
            ></mu-card-title>
            <mu-card-text>
              <van-form @submit="submit"> 

                <van-cell-group title="开发人员">
                  <van-field v-model="user.name" label="开发人员" required :rules="[{ required: true, message: '请输入开发人员！' }]" readonly/>
                  <van-field v-model="orderForm.kfrybm" label="开发人员编码" placeholder="请先开通his账号,有问题联系王时霞" required :rules="[{ required: true, message: '请输入开发人员编码！' }]" readonly/>
                </van-cell-group>
                <van-notice-bar
                  left-icon="volume-o"
                  :scrollable="false"
                  wrapable
                  style="text-align:left"
                  text="开发人员编码如为空，请先联系人资开通HIS账号，HIS账号需要与企微账号保持一致，开通后1小时后自动更新，有问题请联系王时霞。"
                />
                <van-cell-group title="患者信息">
                  <van-field v-model="orderForm.mobile" type="digit" label="联系人手机号" placeholder="请输入联系人手机号码" @blur="getPatient()" required :rules="[{ required: true, message: '请请输入联系人手机号码！' }]"/>
                  <van-field 
                    v-model="orderForm.name" 
                    label="患者姓名" 
                    placeholder="请输入患者姓名" 
                    required 
                    :rules="[{ required: true, message: '请选择患者姓名！' }]"
                  
                  >
                    <template #button>
                      <van-button size="small" type="primary" @click="openPatient()" native-type="button">选择就诊人</van-button>
                    </template>
                  </van-field>
                  <van-action-sheet description="请选择就诊人" v-model="his_patients.show" :actions="his_patients.data" @select="confirmPatient" />
                  <van-field v-model="orderForm.lxrgx_name" label="患者是联系人的" required placeholder="请选择关系" @click="relations.show = true" :rules="[{ required: true, message: '请选择关系' }]" readonly is-link v-if="!orderForm.brid" />
                  <van-action-sheet v-model="relations.show" :actions="relations.data" @select="onSelectLxrgx" />

                  
                  <van-field v-model="orderForm.lxrxm" label="联系人姓名" placeholder="请输入联系人姓名" required :rules="[{ required: true, message: '请输入联系人姓名！' }]" v-if="orderForm.lxrgx !=1 "/>
                  <van-field name="radio" label="患者性别" required :rules="[{ required: true, message: '请选择性别！' }]" v-if="!orderForm.brid">
                    <template #input>
                      <van-radio-group v-model="orderForm.sex" direction="horizontal">
                        <van-radio name="1">男</van-radio>
                        <van-radio name="2">女</van-radio>
                        <van-radio name="3">未知</van-radio>
                      </van-radio-group>
                    </template>
                  </van-field>
                  <SelectItem label="缺牙数量" :value="orderForm.qysl_name" :options="qyslOptions" @onSelect="onSelectQysl" v-if="!orderForm.brid" />
                  <van-field v-model="orderForm.sfzh" label="身份证号码" placeholder="请输入身份证号码"  @blur="validateIdCard(orderForm.sfzh)" v-if="!orderForm.brid" />
                  <van-field v-model="orderForm.txdz" label="通讯地址" placeholder="请输入通讯地址" v-if="!orderForm.brid" />
                </van-cell-group>

                <Lyqd :orderForm="orderForm" :dicts="dicts" @change="changeLyqdData" /> 
                <Yuyue :orderForm="orderForm" @change="changeYuyueData"/>   

                <van-cell-group style="margin-bottom:10px;">
                  <van-field 
                    rows="1"
                    autosize
                    type="textarea"
                    v-model="orderForm.remark" 
                    label="备注" 
                    placeholder="请输入备注内容" 
                  />
                </van-cell-group>

                <!-- <mu-button large full-width round :disabled="loading" v-loading="loading" @click="submit()" color="green500"
                  >提交预约</mu-button
                > -->
                <van-button 
                  type="primary" 
                  round 
                  :loading="loading" 
                  native-type="submit"
                  block>
                  提交预约
                </van-button>
              </van-form>
            </mu-card-text>
          </mu-card>
        </mu-col>
      </mu-row>
    </mu-container>
    <WxWorkFooter shift="yuyue"/>
  </div>
</template>

<script>
// @ is an alias to /src
import WxWorkFooter from "../../components/WxWorkFooter.vue";
import SelectItem from "./components/SelectItem.vue";
import Lyqd from "./components/Lyqd.vue";
import Yuyue from "./components/Yuyue.vue";
// import SelectItem from "./components/SelectItem.vue";
import dayjs from 'dayjs'
import {
  validate,
  province,
  birthDate,
  gender,
  pattern,
} from 'chinese-idcard-checker';

export default {
  name: "Home",
  components: {WxWorkFooter,Lyqd,Yuyue,SelectItem},
  data() {
    return {
      loading:false,
      min_date: new Date(2022, 5, 13),
      max_date: new Date(2022, 5, 20),
      hospitals: [],
      dicts:[],
      offices:{
        show:false,
        data:[],
      },
      doctors: {
        show:false,
        data:[]
      },
      items:[],
      user: {},
      order_date: {
        show:false,
      },
      order_time: {},
      qyslOptions:[],
      orderForm: {
        kfry_userid: "",
        kfrybm: "",
        mobile: "",
        brid: "",
        name: "",
        sfzh:'',
        txdz:'',
        qysl:'',
        qysl_name:'',
        csrq:'',
        lxrgx:1, //联系人关系
        lxrgx_name:'本人',
        lxrxm:'',//联系人姓名
        sex:"",
        city_code:'',
        lybm:"",//来源编码
        qdbm:"",//渠道编码
        jgbm: "",
        yyksbm:"",
        yysxxh:"",
        yysx_name:"", //预约事项内容
        rybm: "", //医生人员编码
        doctor_userid:"",
        doctor_name:"",
        doctor_skill: "",
        date: "",
        time: "",
        items:"",
        period_time: "15",
        is_daiyuyue: 1,

        jsrbm:'', // 介绍人病人id
        jsrxm:'', // 介绍人姓名
        jsrsjh:'', // 介绍人手机号
        yhzgxbm:'', // 介绍人关系编码
        dzysbm:'', // 点诊医生
        dzzjzlbm:'', // 点诊专家助理
        ykfrgxbm:'', // 与开发人关系id
        ykfrgx:'', // 与开发人关系
        hhrid:'', //合伙人id
        hhr_name:'', //合伙人姓名
        hhrtjrbm:'', //合伙人推荐人编码
        hhrtjrxm:'', //合伙人推荐人姓名
        hzptid:'', //合作平台id
        hzpt_name:'', //合作平台名称
        xmfzrybm:'',
        xmfzrymc:''
      },
      his_patients: {
        show: false,
        data: [],
      },
      relations:{
        show:false,
        data:[]
      },

      
    };
  },
  created() {
    this.getUserinfo();
    setTimeout(() => {
      this.getDicts();
    }, 1000);
    // this.getDicts()
    //   this.get_date();
    // console.log(dayjs())
    document.title = "企业微信代预约";
  },
  methods: {
    getUserinfo() {
      this.$http.get("user_info").then((res) => {
        if(res.data.code == 204){
          this.$router.push({'name':'WxWorkLogin'})
          return false;
        }
        this.user = res.data.data;
        this.orderForm.kfry_userid = res.data.data.id;
        this.orderForm.kfrybm = res.data.data.rybm;
      });
    },
    async getDicts() {
      const res = await this.$http.get("dict")
      if (res.data.code !== 200) return false
      this.dicts = res.data.data;
      this.relations.data = res.data.data.filter((item) => {
        return item.flbm == 1038;
      });
      this.qyslOptions = res.data.data.filter((item) => {
        return item.flbm == 1054;
      });
    },
    async getPatient() {
      
      this.orderForm.brid = '';
      this.orderForm.sex = '';
      // this.orderForm.name = '';
      this.his_patients.data = [];
      if(this.orderForm.mobile.length < 10){
        return false
      }
      let res = await this.$http.post("his_patient", this.orderForm)
      if (res.data.code == 201) {
        return []
      }
      //默认选择第一个
      this.orderForm.brid = res.data.data[0].brid;
      this.orderForm.name = res.data.data[0].name;
      this.orderForm.sex = res.data.data[0].xb;
      this.orderForm.lybm = res.data.data[0].lybm;
      
      this.orderForm.qdbm = res.data.data[0].qdbm;
      this.his_patients.show = true;
      this.his_patients.data = res.data.data;
      return res.data.data
      
    },
    async openPatient(){
      if(this.orderForm.mobile == ''){
        this.$dialog.alert({
          message: "请先输入手机号码",
        })
        return false
      }
      if(this.orderForm.mobile.length != 11){
        this.$dialog.alert({
          message: "请输入正确的手机号码",
        })
        return false
      }

      if(this.his_patients.data.length == 0){
        let res = await this.getPatient()
        if(res.length == 0){
          this.$dialog.alert({
            message: "该手机号暂无就诊人！",
          })
        }
        
        return false
      }
      
      this.his_patients.show = true
    },
    confirmPatient(patient) {
      this.orderForm.brid = patient.brid;
      this.orderForm.name = patient.name;
      this.orderForm.sex = patient.xb;
      this.his_patients.show = false;
    },
    //更新来源渠道后  更新数据
    // changeLyQd(item){
    //   this.orderForm.city_code = item.city_code
    //   this.orderForm.lybm = item.lybm
    //   this.orderForm.qdbm = item.qdbm
    //   this.orderForm.jgbm = item.jgbm
    //   this.orderForm.jsrbm = item.jsrbm
    //   this.orderForm.jsrxm = item.jsrxm
    //   this.orderForm.jsrsjh = item.jsrsjh
    //   this.orderForm.yhzgxbm = item.yhzgxbm
    //   this.orderForm.ykfrgxbm = item.ykfrgxbm
    //   this.orderForm.dzysbm = item.dzysbm
    //   this.orderForm.dzzjzlbm = item.dzzjzlbm
    //   this.orderForm.hhrid = item.id
    //   this.orderForm.hhr_name = item.name
    //   this.orderForm.hhrtrjbm = item.trjbm
    //   this.orderForm.hhrtjrxm = item.trjxm
    //   this.orderForm.hzptid = item.hzptid
    //   this.orderForm.hzpt_name = item.hzpt_name
    // },
    // onSelectPartner(item){
    //   this.orderForm.hhrid = item.id
    //   this.orderForm.hhr_name = item.name
    //   this.orderForm.hhrtjrbm = item.tjrbm
    //   this.orderForm.hhrtjrxm = item.tjrxm
    // },
    changeLyqdData(key,value){
      this.orderForm[key] = value
      // if(key === 'city_code'){
      //   this.getHospital()
      // }
    },
    //更新预约信息
    // changeYuyue(item){
    //   this.orderForm.jgbm = item.jgbm //机构编码
    //   this.orderForm.yyksbm = item.yyksbm //预约科室编码
    //   this.orderForm.yysxxh = item.yysxxh //预约事项
    //   this.orderForm.yysx_name = item.yysx_name
    //   this.orderForm.rybm = item.rybm //医生人员编码
    //   this.orderForm.doctor_userid = item.doctor_userid //医生人员编码
    //   this.orderForm.date = item.date
    //   this.orderForm.time = item.time
    //   this.orderForm.period_time = item.period_time
    // },
    changeYuyueData(key,value){
      this.orderForm[key] = value
    },

    //选择联系人关系
    onSelectLxrgx(item){
      this.relations.show = false
      this.orderForm.lxrgx = item.zdbm
      this.orderForm.lxrgx_name = item.name
    }, 
    onSelectQysl(item){
      // consoel.log(item)
      this.orderForm.qysl = item.zdbm
      this.orderForm.qysl_name = item.name
    },
    validateIdCard(idcard) {
      // console.log(idcard)
      if(!idcard) return true;
      if(!validate(idcard)) {
        this.$dialog.alert({
          message: "身份证号码格式不正确,请重新输入!",
          theme: "round-button",
        });
        return false;
      }
      this.orderForm.csrq = dayjs(birthDate(idcard)).format('YYYY-MM-DD')
      // console.log(this.orderForm.csrq)
      return true;
      
    },

    submit() {
      // this.loading = true
      // console.log(this.orderForm)
      // return false;
      const flag = this.validateIdCard(this.orderForm.sfzh)
      if(!flag) return;
      if(this.orderForm.time == ''){
        this.$dialog.alert({
          message: "请选择预约时间",
          // theme: "round-button"
        })
        return false;
      }
      if(this.orderForm.lxrgx == 1){
        this.orderForm.lxrxm = this.orderForm.name
      }
      // console.log(this.orderForm)
      // return false;
      if(this.orderForm.kfrybm == '' || this.orderForm.kfrybm == null){
        this.$dialog.alert({
          message: "开发人员编码为空，请联系管理员！",
          theme: "round-button"
        })
        return false;
      }
      if (this.orderForm.jgbm == "" || this.orderForm.jgbm == null) {
        this.$dialog.alert({
          message: "机构编码为空，请选择机构！",
          theme: "round-button"
        })
        return false;
      }
      if(this.loading){
        this.$dialog.alert({
          message: "重复提交！，请稍等！",
          theme: "round-button"
        })
        return false;
      }
      this.loading = true
  
      this.$http.post("wx_work_order", this.orderForm).then((res) => {
        this.loading = false;

        if(res.data.code !== 200){
          this.$dialog.alert({
            message: res.data.msg,
            theme: "round-button"
          })
          return false;
        }
        this.$dialog.alert({
          message: "预约成功",
          theme: "round-button"
        })
        .then((res) => {
          this.$router.push({'name':'WxWorkOrderList'})
        });
      });
    },
  },
  // watch:{
  //   'orderForm.jgbm':function(val){
  //     this.get_doctors()
  //   }
  // },
  mounted() {
    if (this.$route.query.from_user) {
      localStorage.from_user = this.$route.query.from_user;
    }
  },
};
</script>
<style>
.van-field__label{
  width:7.2em !important;
}
</style>
<style scoped>
.container{
  padding-left:0px;
  padding-right:0px;
}
.van-cell-group__title{
  margin-top:20px;
  margin-bottom:5px;
  font-size:16px;
  color:#07c160;
}
.van-cell{
  padding:16px !important;
}
.van-cell__title{
  text-align:left;
}
.van-cell__value{
  text-align:center !important;
}

.appointment {
  background: url(/static/images/yuyue_bg.png) no-repeat fixed top;
  padding-bottom:45px;
}
.appointment form{
  padding:10px;
}
.time_select {
  background-color: #3f51b5 !important;
  color: white !important;
}
.notice {
  text-align: left;
}

</style>
